import { aceptarKilometros, tipoGestionAlbaran, tipoGestionTramite, tipoPaquete, tipoReembolso, tipoSeguro, tipoVehiculo } from "app/modules/envios/components/camposEnvios/CamposEnviosNacex";
import { FormSelect, FormTextInput } from "../../../../componentes/formularios/core";
import Yup, { emptyStringToNull } from "../../../../librerias/yup";
import TipoMercancia from "../camposAdicionales/TipoMercancia";

export const objetoSchemaCamposAdicionales = {
	numTarifario: Yup.string().nullable(),
	instrDevolucion: Yup.string().nullable(),
	valorNeto: Yup.number().transform(emptyStringToNull).nullable().default(null),
	incoterm: Yup.string().nullable(),
	codigoTipoMercancia: Yup.string().nullable(),
	ibanReembolso: Yup.string().nullable(),
	tipoPesoVol: Yup.string().nullable(),
	descDeclarado: Yup.string().nullable(),
	tipoSeguro: Yup.string().nullable(),
	importeValorAsegurar: Yup.number().transform(emptyStringToNull).nullable().default(null),
	gestionAlbaran: Yup.string().nullable(),
	gestionTramite: Yup.string().nullable(),
	tipoReembolso: Yup.string().nullable(),
	tipoVehiculo: Yup.string().nullable(),
	aceptarKilometros: Yup.string().nullable(),
};

export const ConfigCampos = {
	//CORREOS
	2: [
		{
			componente: FormTextInput,
			name: "numTarifario",
			label: "Num Arancelario",
			props: {
				tooltip: "Num arancelario Correos",
			},
			default: "",
			idImportacion: 73,
		},
		{
			componente: FormSelect,
			name: "instrDevolucion",
			label: "Instrucciones Devolución",
			props: {
				permitirValoresNoExistentes: false,
			},
			opciones: [
				{ id: "D", nombre: "Devolver al remitente" },
				{ id: "A", nombre: "Tratar como abandonado" },
			],
			default: "",
			idImportacion: 74,
		},
		{
			componente: FormTextInput,
			label: "Valor neto (Euros)",
			name: "valorNeto",
			props: {
				type: "number",
				tooltip: "El valor de los productos en euros",
			},
			default: null,
			idImportacion: 75,
		},
		{
			componente: FormTextInput,
			label: "Cuenta Reembolso (IBAN)",
			name: "ibanReembolso",
			props: {
				tooltip: "Cuenta IBAN para ingresar el importe del reembolso",
			},
			default: "",
			idImportacion: 88,
		},

		{
			componente: FormSelect,
			name: "tipoPesoVol",
			label: "Peso volumétrico recogida",
			props: {},
			opciones: [
				{ id: "10", nombre: "Sobres" },
				{ id: "30", nombre: "Mediano (caja con paquete folios)" },
				{ id: "50", nombre: "Muy grande (mayor que caja 80x80x80 cm)" },
				{ id: "20", nombre: "Pequeño (caja zapatos)" },
				{ id: "40", nombre: "Grande (caja 80x80x80 cm)" },
				{ id: "60", nombre: "Palet" },
			],
			default: "10",
			idImportacion: null,
		},
	],
	//GLS
	4: [
		{
			componente: FormSelect,
			name: "incoterm",
			label: "Incoterm",
			props: {},
			opciones: [
				{ id: "", nombre: "Nothing" },
				{ id: "10", nombre: "Incoterm 10 DDP" },
				{ id: "18", nombre: "Incoterm 18 (DDP, VAT pre-registration" },
				{ id: "20", nombre: "Incoterm 20 DAP" },
				{ id: "30", nombre: "Incoterm 30 DDP, IVA no pagado" },
				{ id: "40", nombre: "Incoterm 40 DAP, despachado" },
				{ id: "50", nombre: "Incoterm 50c DDP, bajo valor" },
				{ id: "60", nombre: "Incoterm 60" },
				{ id: "70", nombre: "Incoterm 70" },
			],
			default: "",
			idImportacion: 76,
		},
		{
			componente: FormTextInput,
			label: "Mercancia valor declarado",
			name: "descDeclarado",
			props: {
				tooltip: "Descripción de la mercanía (Valor declarado)",
			},
			default: "",
			idImportacion: 97,
		},
	],
	//Nacex
	8: [
		{
			componente: FormSelect,
			name: "tipoPesoVol",
			label: "Tipo de paquete",
			props: {},
			opciones: tipoPaquete,
			default: "2",
			idImportacion: 182,
		},
		{
			componente: FormSelect,
			name: "tipoReembolso",
			label: "Tipo de reembolso/adelanto",
			props: {},
			opciones: tipoReembolso,
			default: "N",
			idImportacion: 183,
		},
		{
			componente: FormSelect,
			name: "gestionTramite",
			label: "Código de gestión o trámite",
			props: {},
			opciones: tipoGestionTramite,
			default: "N",
			idImportacion: 184,
		},
        {
			componente: FormSelect,
			name: "tipoSeguro",
			label: "Tipo de seguro",
			props: {},
			opciones: tipoSeguro,
			default: "N",
			idImportacion: 185,
		},
        {
			componente: FormTextInput,
			label: "Importe valor a asegurar (Euros)",
			name: "importeValorAsegurar",
			props: {
				type: "number",
				tooltip: "El valor de los productos en euros",
			},
			default: null,
			idImportacion: 186,
		},
        {
			componente: FormSelect,
			name: "gestionAlbaran",
			label: "Gestión del albarán",
			props: {},
			opciones: tipoGestionAlbaran,
			default: "N",
			idImportacion: 187,
		},
        {
			componente: FormTextInput,
			label: "Descripción de la mercancía",
			name: "descDeclarado",
			props: {
				tooltip: "Descripción de la mercancía (Para envíos internacionales)",
			},
			default: "",
			idImportacion: 97,
		},
		{
			componente: FormSelect,
			name: "tipoVehiculo",
			label: "Tipo de vehículo",
			props: {},
			opciones: tipoVehiculo,
			default: "C",
			idImportacion: 188,
		},
		{
			componente: FormSelect,
			name: "aceptarKilometros",
			label: "¿Aceptar kilometros?",
			props: {},
			opciones: aceptarKilometros,
			default: "N",
			idImportacion: 189,
		},
	],
	//CTT
	12: [
		{
			componente: TipoMercancia,
			name: "codigoTipoMercancia",
			label: "Tipo de mercancia",
			props: {},
			funcPropsAdicionales: "devolverPropsTipoMercancia",
			idImportacion: 77,
		},
	],
	//UPS
	9: [
		{
			componente: FormSelect,
			name: "tipoPesoVol",
			label: "Tipo de paquete",
			props: {
				defaultValue: "02",
			},
			opciones: [
				{ id: "01", nombre: "Carta UPS" },
				{ id: "02", nombre: "Paquete suministrado por cliente (Habitual)" },
				{ id: "03", nombre: "Tube 04 = PAK" },
				{ id: "21", nombre: "UPS Express Box" },
				{ id: "24", nombre: "UPS 25kg Box" },
				{ id: "25", nombre: "UPS 10kg Box" },
				{ id: "30", nombre: "Pallet (Freight)" },
				{ id: "2a", nombre: "Small Express Box" },
				{ id: "2b", nombre: "Medium Express Box" },
				{ id: "2c", nombre: "Large Express Box" },
				{ id: "56", nombre: "Flats" },
				{ id: "57", nombre: "Parcels" },
				{ id: "58", nombre: "BPM" },
				{ id: "59", nombre: "First Class" },
				{ id: "60", nombre: "Priority" },
				{ id: "61", nombre: "Machineables" },
				{ id: "62", nombre: "Irregulars" },
				{ id: "63", nombre: "Parcel Post" },
				{ id: "64", nombre: "BMP Parcel" },
				{ id: "65", nombre: "Media Mail" },
				{ id: "66", nombre: "BMP Flat" },
				{ id: "67", nombre: "Standard Flat" },
			],
			default: "",
			idImportacion: 182,
		},
		{
			componente: FormTextInput,
			label: "Mercancia valor declarado",
			name: "descDeclarado",
			props: {
				tooltip: "Descripción de la mercanía (Valor declarado)",
			},
			default: "",
			idImportacion: 97,
		},
		{
			componente: FormSelect,
			label: "¿Cargar aduana e impuestos al emisor?",
			name: "incoterm",
			props: {
				tooltip: "El remitente paga todos los gastos de aduanas e impuestos",
			},
			opciones: [
				{ id: "DDP", nombre: "Sí" },
				{ id: "DAP", nombre: "No" },
			],
			default: "",
			idImportacion: null,
		},
	],
};
